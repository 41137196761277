import { createSlice } from "@reduxjs/toolkit";
import tagStatus from "../config/tagStatus";

const getInitialState = () => {
  return {
    bidsData: [],
    chartData: [],
    takeProfit: undefined,
    stopLoss: undefined,
    showTakeProfitStopLoss: false,
  };
};

export const bidsSlice = createSlice({
  name: "bids",
  initialState: getInitialState(),
  reducers: {
    setBidsData: (state, action) => {
      state.bidsData = action.payload;
    },
    addBidsData: (state, action) => {
      state.bidsData = [action.payload, ...state.bidsData];
    },
    updateBidsData: (state, action) => {
      const filtered = state.bidsData.filter((data) => {
        return data.status?.text !== tagStatus.loading.text;
      });
      state.bidsData = [action.payload, ...filtered];
    },
    setChartData: (state, action) => {
      state.chartData = action.payload;
    },
    addChartData: (state, action) => {
      state.chartData = [...state.chartData, action.payload];
    },

    setTakeProfit: (state, action) => {
      state.takeProfit = action.payload;
    },
    setStopLoss: (state, action) => {
      state.stopLoss = action.payload;
    },
    setShowTakeProfitStopLoss: (state, action) => {
      state.showTakeProfitStopLoss = action.payload;
    },
  },
});

export const {
  setBidsData,
  addBidsData,
  setChartData,
  addChartData,
  updateBidsData,
  setTakeProfit,
  setStopLoss,
  setShowTakeProfitStopLoss,
} = bidsSlice.actions;

export default bidsSlice.reducer;
